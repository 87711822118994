import styled from "styled-components";
import { colors } from "douglasfirearms-shared";

export const NavWrapper = styled.nav`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.backgroundDark};
  color: ${colors.primary};
  position: relative;
  > :first-child {
    margin-right: auto;
  }
`;

export const Nav = styled.ul`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  padding-right: 32px;
`;

export const NavItem = styled.li`
  opacity: 1;
  > a {
    text-decoration: none;
    color: white!important;
  }
  + li {
    margin-left: 20px;
    position: relative;
  }
`;
