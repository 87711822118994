export const CourseStatus = {
  ACTIVE: "ACTIVE",
  CANCELLED: "CANCELLED",
};

export const SignupStatus = {
  PAYMENT_FAILURE: "PAYMENT_FAILURE",
  INVOICE_PAYMENT_REQUIRED: "INVOICE_PAYMENT_REQUIRED",
  SIGNUP_COMPLETE: "SIGNUP_COMPLETE",
};

export const OperatingSystem = {
  MAC: 'mac',
  WINDOWS: 'windows',
  IOS: 'ios',
  ANDROID: 'android',
  LINUX: 'linux',
  UNKNOWN: 'unknown'
}