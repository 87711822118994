import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Section, SectionHeader, SectionSpinner } from "../components/Section";
import { formatDate } from "../services/date";
import { useParams } from "react-router-dom";
import SignupTable from "../components/SignupTable";

let e = 0;
let PageStates = {
  LOADING: e++,
  SHOW_SIGNUPS: e++,
  NO_SIGNUPS: e++,
  ERROR: e++,
};

export default function () {
  // Get timestamp from url params
  const { timestamp } = useParams();

  // Query Signups
  const GET_SIGNUPS = gql`
    {
      signups(courseTimestamp: ${timestamp}) {
        signupId
        courseTimestamp
        name
        email
        phoneNumber
        status
      }
    }
  `;
  const { loading, error, data } = useQuery(GET_SIGNUPS);
  let signups = data?.signups;
  if (signups) {
    signups = [...signups].sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  // Set Page State
  let pageState;
  if (loading) pageState = PageStates.LOADING;
  else if (error) pageState = PageStates.ERROR;
  else if (signups && !signups.length) pageState = PageStates.NO_SIGNUPS;
  else pageState = PageStates.SHOW_SIGNUPS;

  // Render the data based on page state
  const renderSignups = () => {
    switch (pageState) {
      case PageStates.LOADING:
        return <SectionSpinner />;
      case PageStates.SHOW_SIGNUPS:
        return <SignupTable signups={signups} />;
      case PageStates.ERROR:
        return <div>An error occurred loading your signups</div>;
      case PageStates.NO_SIGNUPS:
        return <div>No signups... yet</div>;
      default:
        return <div>An unknown error occurred loading your signups. Try refreshing the page.</div>
    }
  };

  // Inner header fonts
  const innerHeaderStyle = {
    fontSize: "22px",
    fontWeight: "bold",
    marginBottom: "20px",
    display: "block",
  };

  // Render DOM
  return (
    <Section>
      <SectionHeader as="h1">{formatDate(parseInt(timestamp))}</SectionHeader>
      <div>
        <strong style={innerHeaderStyle}>Signups</strong>
        {renderSignups()}
      </div>
    </Section>
  );
}
