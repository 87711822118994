import React from "react";
import { useTable } from "react-table";
import styled from "styled-components";
import { SignupStatus } from "../../constants";
import { colors } from "douglasfirearms-shared";
import SignupTableActions from "./SignupTableActions";
import axios from "axios";
import { toast } from "react-toastify";

/**
 * Render a status badge based on the signup's status
 * @param {SignupStatus} status The signup status string 
 */
const StatusBadge = ({ status }) => {
  let badgeData;

  const badgeColors = {
    warning: "#cec07c",
    success: "green",
    danger: "red",
  };

  switch (status) {
    case SignupStatus.INVOICE_PAYMENT_REQUIRED:
      badgeData = {
        text: "Payment Required",
        background: badgeColors.warning,
        color: colors.accentDark,
      };
      break;
    case SignupStatus.PAYMENT_FAILURE:
      badgeData = {
        text: "Payment Failed",
        background: badgeColors.danger,
      };
      break;
    case SignupStatus.SIGNUP_COMPLETE:
      badgeData = {
        text: "Payment Complete",
        background: badgeColors.success,
      };
      break;
    default:
      badgeData = {
        text: "Unknown",
        background: badgeColors.danger,
      };
      break;
  }

  const BadgeElem = styled.div`
    background-color: ${badgeData.background};
    font-size: 16px;
    border-radius: 4px;
    color: ${badgeData.color ?? colors.accentLight};
    padding: 8px;
  `;

  return <BadgeElem>{badgeData.text}</BadgeElem>;
};

export default function ({ signups }) {

  // Use React.useMemo here so react doesn't re-render the universe on ever render
  const data = React.useMemo(
    () =>
      signups.map((signup) => {
        const { status, ...signupData } = signup;
        return {
          status: <StatusBadge status={status} />,
          ...signupData,
        };
      }),
    [signups]
  );

  /**
   * Set a signup as paid
   */
  const setPaymentComplete = (signupIdx) => {
    const signup = signups[signupIdx]
    axios.post(process.env.REACT_APP_API_URL + `/signups/set-payment-complete`, {
      signupId: signup.signupId,
      courseTimestamp: signup.courseTimestamp
    }).then(() => {
      toast.success("Set as paid successfully")
      window.location.reload();
    }).catch(err => {
      toast.error("Failed to set as paid... Go ahead and tell Jerry to get this shit figured out.")
    })
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
      },
      {
        Header: "Status",
        accessor: "status",
      },
    ],
    []
  );

  const TableStyles = styled.div`
    max-width: 100%;
    overflow: scroll;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    } 
    table {
      border-spacing: 0;
      border: 1px solid black;

      tr {
        :last-child {
          td {
            border-bottom: 0;
          }
        }
      }

      th,
      td {
        margin: 0;
        padding: 0.5rem;
        border-bottom: 1px solid black;
        border-right: 1px solid black;

        :last-child {
          border-right: 0;
        }
      }
    }
  `;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  return (
    <div>
      <SignupTableActions signups={signups} />
      <TableStyles>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                ))}
                <th>Actions</th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                  <td>
                    {
                      signups[i]?.status === SignupStatus.INVOICE_PAYMENT_REQUIRED &&
                      <button type="button" onClick={() => setPaymentComplete(i)}>
                        Set as Paid
                      </button>
                    }
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </TableStyles>
    </div>
  );
}
