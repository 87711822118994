import React from "react";
import { ApolloProvider } from "@apollo/client";
import Layout from "./components/Layout";
import { Courses, Course, CreateCourse, Logout } from "./pages";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apolloClient from "./apollo-client";

export default function (props) {
  return (
    <ApolloProvider client={apolloClient}>
      <div className="App">
        <ToastContainer />
        <Router>
          <Layout>
            <Switch>
              <Route exact path="/logout">
                <Logout />
              </Route>
              <Route exact path="/courses/create" component={CreateCourse} />
              <Route exact path="/courses/:timestamp" component={Course} />
              <Route exact path="/">
                <Courses />
              </Route>
              <Route path="/">
                <Redirect to="/" />
              </Route>
            </Switch>
          </Layout>
        </Router>
      </div>
    </ApolloProvider>
  );
}
