import styled from "styled-components";

export default styled.div`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  width: ${(props) => props.size ?? "20px"};
  height: ${(props) => props.size ?? "20px"};
  border-radius: 50%;
  border-top: ${(props) => props.thickness ?? "2px"} solid #831b21;
  border-right: ${(props) => props.thickness ?? "2px"} solid transparent;
  animation: spinner 0.45s linear infinite;
`;
