import styled from "styled-components";

export const Container = styled.div`
  max-width: 992px;
  width: 100%;
  margin: 0 auto;
  padding: 32px;
  padding-top: 64px;
  @media (max-width: 767px) {
    padding: 24px;
    padding-top: 32px;
  }
`;
