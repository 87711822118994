import React, { useState } from "react";
import { Section, SectionHeader } from "../components/Section";
import Box from "../components/Box";
import Datepicker from "react-datepicker";
import styled from "styled-components";
import { formatDate, getNextSaturdayDateTime } from "../services/date";
import { Button } from "douglasfirearms-shared";
import { gql, useMutation } from "@apollo/client"
import { toast } from "react-toastify";
import * as moment from "moment";

const DEFAULT_COURSE_PRICE = 100
const DEFAULT_COURSE_DEPOSIT = 20

const CREATE_COURSE_GQL = gql`
  mutation createCourse($timestamp: Int!, $dateDisplay: String!) {
    createCourse(timestamp: $timestamp, dateDisplay: $dateDisplay, price: ${DEFAULT_COURSE_PRICE}, initialDeposit: ${DEFAULT_COURSE_DEPOSIT}) {
      timestamp
      dateDisplay
      price
      initialDeposit
    }
  }
`;

const StyledDatepicker = styled(Datepicker)`
  div.react-datepicker-time__input {
    max-width: 150px;
    width: 100%;
    > input.react-datepicker-time__input {
      width: 100%;
    }
  }
`;

let successToastSent = false
export default function () {
  const [courseTimestamp, setCourseTimestamp] = useState(
    getNextSaturdayDateTime().toDate()
  );
  const [submitting, setSubmitting] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const [createCourse, { data }] = useMutation(CREATE_COURSE_GQL)

  const handleDatepickerChange = (timestamp) => {
    setCourseTimestamp(timestamp)
  }

  const submitCourse = () => {
    if (courseTimestamp) {
      const params = {
        variables: {
          timestamp: moment(courseTimestamp).unix(),
          dateDisplay: formatDate(courseTimestamp)
        }
      }
      console.log('creating course with params: ', params)

      setButtonDisabled(true)
      setSubmitting(true)
      createCourse(params)
        .catch(err => {
          console.error(err)
          toast.error(
            "Error creating course. Please refresh the page and try again."
          );
          setButtonDisabled(false)
          // TODO handle if tried to submit a duplicate course
        })
        .finally(() => {
          setSubmitting(false)
        })
    }
  }

  if (data?.createCourse?.timestamp) {
    if (!successToastSent) {
       toast.success("Successfully created course!")
    }
    successToastSent = true
    
    setTimeout(() => {
      window.location.href = "/"
    }, 2500)
  }
  
  return (
    <Section>
      <SectionHeader as="h1" style={{ justifyContent: "center" }}>
        Create a Course
      </SectionHeader>
      <Box style={{ textAlign: "center" }}>
        <p>Enter the date and time of the course.</p>
        <StyledDatepicker
          selected={courseTimestamp}
          onChange={(timestamp) => handleDatepickerChange(timestamp)}
          showTimeInput
          inline
        />
        <p>
          Selected date:{" "}
          <strong style={{ fontWeight: 700 }}>{formatDate(courseTimestamp)}</strong>
        </p>
        <Button onClick={submitCourse} disabled={buttonDisabled} showSpinner={submitting}>
          Create course for {formatDate(courseTimestamp, "MM/DD/YY hh:mma")}
        </Button>
      </Box>
    </Section>
  );
}
