import * as moment from "moment";

const DATE_FORMAT = "dddd, MMMM DD | hh:mm A";
export const formatDate = (dt, dateFormat = DATE_FORMAT) => {
  if (typeof dt === "number") {
    return moment.unix(dt).format(dateFormat);
  } else {
    return moment(dt).format(dateFormat);
  }
};

const SATURDAY = 6;
export const getNextSaturdayDateTime = () => {
  const today = moment().isoWeekday();
  let dt = moment()
  if (today >= SATURDAY) {
    dt = dt.add(1, "week")
  }
  return dt.isoWeekday(SATURDAY).hour(8).minute(0).second(0)
};
