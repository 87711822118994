import { OperatingSystem } from '../constants'

/**
 * Convert an array of strings to an enumeration.
 * Usage: enumerate('a', 'b', 'c') => { a: 1, b: 2, c: 3 }
 * @param  {...any} values
 * @returns {object} an enumerated version of the inputs
 */
export const enumerate = (...values) => {
  let i = 0;
  let enumeration = {};
  values.forEach((value) => {
    enumeration[value] = ++i;
  });
  return enumeration;
};

/**
 * Determine the operating system
 */
export const getOperatingSystem = () => {
var userAgent = window?.navigator?.userAgent,
    platform = window?.navigator?.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = OperatingSystem.UNKNOWN;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = OperatingSystem.MAC;
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = OperatingSystem.IOS;
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = OperatingSystem.WINDOWS;
  } else if (/Android/.test(userAgent)) {
    os = OperatingSystem.ANDROID;
  } else if (/Linux/.test(platform)) {
    os = OperatingSystem.LINUX;
  }

  return os;
}