import React from "react";
import styled from "styled-components";
import Spinner from "../Spinner";

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &,
  h1,
  h2,
  h3 {
    font-size: 44px;
    font-weight: 700;
    text-align: left;
  }
`;

export const Section = styled.section`
  > * + * {
    margin-top: 20px;
  }
  > ${SectionHeader} + * {
    margin-top: 42px;
  }
`;

export const SectionSpinner = function () {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Spinner size="42px" thickness="4px" />
    </div>
  );
};
