import styled from "styled-components";
import { colors } from "douglasfirearms-shared";

export default styled.div`
  max-width: ${(props) => props.desktopWidth || props.width || "600px"};
  width: 100%;
  margin: 16px auto;
  background: ${colors.backgroundLight};
  padding: 32px;
  box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.09);
  @media (max-width: 767px) {
    padding: 16px;
  }
  > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  > * + * {
    margin-top: 24px;
    @media (max-width: 767px) {
      margin-top: 16px;
    }
  }
`;
