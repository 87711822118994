import React from "react";
import { Helmet } from "react-helmet";
import Nav from "../Nav";
import { GlobalStyle, Main } from "./style";
import "rsuite-table/dist/css/rsuite-table.css";
import { Reset } from "styled-reset";
import { Container } from "../PageLayout";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

export default function ({ children } = {}) {
  return (
    <React.Fragment>
      <Reset />
      <GlobalStyle />
      <Helmet>
        <html lang="en" />
        <title>Admin | Douglas Firearms Training</title>
        <meta name="description" content="Douglas Firearms Training Admin Panel" />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Raleway:wght@600;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Nav />
      <Container>
        <Main>{children}</Main>
      </Container>
    </React.Fragment>
  );
}
