import React from "react";
import { getOperatingSystem } from '../../services/util'
import { OperatingSystem } from '../../constants'

const os = getOperatingSystem()

const getSmsHref = (signups) => {
  const phoneNumberStr = signups.map(signup => signup.phoneNumber).join(',')
  switch (os) {
    case OperatingSystem.MAC:
    case OperatingSystem.IOS:
      return `sms://open?addresses=${phoneNumberStr}`
    default:
      return `sms:${phoneNumberStr}`
  }
}

export default function ({ signups }) {
  return (
    <div style={{marginBottom: '24px'}}>
      <a href={getSmsHref(signups)}>
        Send a group text
      </a>
    </div>
  )
}