import React from "react";
import * as AuthService from "../services/auth";

export default () => {
  AuthService.clearJwtCookie();

  window.location.href = AuthService.getLoginUrl();

  return <div>You are signed out!</div>;
};
