import qs from "qs";
import Cookies from "js-cookie";
const JWT = "dft_jwt";

export const getLoginUrl = () =>
  [
    process.env.REACT_APP_AUTH_URI,
    "?client_id=",
    process.env.REACT_APP_AUTH_CLIENTID,
    "&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=",
    process.env.REACT_APP_BASE_URI,
    "&CognitoCloudFrontEndpoint=https%3A%2F%2Fd3oia8etllorh5.cloudfront.net&CognitoCloudFrontVersion=20191106223759&CustomerCloudFrontEndpoint=d3oia8etllorh5.cloudfront.net&CustomerCloudFrontVersion=20201006123144&SanitizedQueryString=client_id%3D",
    process.env.REACT_APP_AUTH_CLIENTID,
    "%26response_type%token%26scope%3Daws.cognito.signin.user.admin%2Bemail%2Bopenid%2Bphone%2Bprofile%26redirect_uri%3D",
    encodeURIComponent(process.env.REACT_APP_BASE_URI),
    "&loginErrorMessage=You+tried+to+access+a+page+that+requires+authentication%2C+please+try+again+after+authenticating",
  ].join("");

export const setJwtFromUrl = () => {
  // Save pertinent url param data
  let urlParamStr = window.location.hash;
  if (urlParamStr) {
    urlParamStr = urlParamStr.slice(1, urlParamStr.length);
    console.log(urlParamStr);
    const params = qs.parse(urlParamStr);
    console.log(params);
    if (params.id_token) {
      Cookies.set(JWT, params.id_token);
    }
  }
};

export const getJwtFromCookie = () => Cookies.get(JWT);
export const validateJwt = () => {
  const jwt = getJwtFromCookie();
  if (!jwt) {
    window.location.href = getLoginUrl();
  } else {
    return true;
  }
};

export const clearJwtCookie = () => {
  Cookies.remove(JWT);
};
