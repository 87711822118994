import React from "react";
import { CourseLink, CourseLinkInfo, CourseLinkIcon } from "./style";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

export default function ({ course }) {
  return (
    <CourseLink to={`/courses/${course.timestamp}`}>
      <CourseLinkInfo>{course.dateDisplay}</CourseLinkInfo>
      <CourseLinkIcon icon={faChevronRight} />
    </CourseLink>
  );
}
