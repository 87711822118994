import styled, { createGlobalStyle } from "styled-components";
import { fonts } from "douglasfirearms-shared";

export const GlobalStyle = createGlobalStyle`
  html, body, div, span, applet, object, iframe, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, main, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    font-family: ${fonts.text}!important;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${fonts.header}!important;
  }

  body {
    background: #f2f2f2;
    min-height: 100vh;
  }

  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
`;

export const Main = styled.div`
  padding-top: 42px;
  padding-bottom: 64px;
  @media (max-width: 767px) {
    padding-top: 32px;
    padding-bottom: 42px;
  }
`;
