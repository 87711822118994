import React from "react";
import { NavWrapper, Nav, NavItem } from "./style";
import { Logo } from "douglasfirearms-shared";
import { Link } from 'react-router-dom'

export default function () {
  return (
    <NavWrapper>
      <Logo type="bare" />
      <Nav>
        <NavItem>
          <Link to="/">Courses</Link>
        </NavItem>
        <NavItem>
          <Link to="/logout">Logout</Link>
        </NavItem>
      </Nav>
    </NavWrapper>
  );
}
