import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "douglasfirearms-shared";
import { Link } from "react-router-dom";

export const CourseLinkInfo = styled.strong`
  font-size: 20px;
  font-weight: 700;
`;

export const CourseLinkIcon = styled(FontAwesomeIcon)`
  > * {
    color: #666666;
  }
`;

export const CourseLink = styled(Link)`
  padding: 16px;
  background: white;
  box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-decoration: none;
  color: ${colors.accentDark};
  &:hover {
    cursor: pointer;
    ${CourseLinkInfo} {
      text-decoration: underline;
    }
  }
`;
