import {
  ApolloClient,
  InMemoryCache,
  from,
  HttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { setJwtFromUrl, getJwtFromCookie, getLoginUrl } from "./services/auth";

// Save the jwt if it is set in the url
setJwtFromUrl();

// Setup apollo
const link = from([
  // Redirect to cognito login if user isn't authenticated
  onError(({ graphQLErrors }) => {
    if (graphQLErrors?.length) {
      graphQLErrors.forEach((error) => {
        if (error?.extensions?.code === "UNAUTHENTICATED") {
          window.location.href = getLoginUrl();
        }
      });
    }
  }),
  new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL,
    credentials: "include",
  }),
]);

const authLink = setContext((_, { headers }) => {
  const token = getJwtFromCookie();
  if (token) {
    return {
      headers: {
        "x-api-token": token,
        ...headers,
      },
    };
  } else {
    if (process.env.REACT_APP_ENV !== 'local') {
      window.location.href = getLoginUrl();
    }
    return headers;
  }
});

export default new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
});
