import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Section, SectionHeader, SectionSpinner } from "../components/Section";
import CourseLink from "../components/CourseLink";
import { Button } from "douglasfirearms-shared";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

let e = 0;
const PageStates = {
  LOADING: e++,
  NO_COURSES: e++,
  SHOW_COURSES: e++,
  ERROR: e++,
};

export default function () {
  // Get courses
  const GET_COURSES = gql`
    {
      courses {
        timestamp
        dateDisplay
        price
        initialDeposit
      }
    }
  `;
  
  const { loading, error, data } = useQuery(GET_COURSES);
  let courses;
  if (data?.courses) {
    courses = [...data.courses].sort((a, b) => a.timestamp - b.timestamp)
  }

  // Setup page state
  let pageState;
  if (loading) pageState = PageStates.LOADING;
  else if (error) pageState = PageStates.ERROR;
  else if (courses && !courses.length) pageState = PageStates.NO_COURSES;
  else pageState = PageStates.SHOW_COURSES;

  // Render course data based on pageState
  const renderCourses = () => {
    switch (pageState) {
      case PageStates.LOADING:
        return <SectionSpinner />;
      case PageStates.SHOW_COURSES:
        return courses?.map((course, i) => (
          <CourseLink key={i} course={course} />
        ));
      case PageStates.NO_COURSES:
        return <div>No courses found.</div>;
      case PageStates.ERROR:
        return (
          <div>
            <p>
              Error loading course data. Try refreshing the page.
              <br />
              <br />
            </p>
            <p>
              If this problem continues, feel free yell at Jerry Dixon via text,
              call, or facebook rant.
            </p>
          </div>
        );
      default:
        return (
          <div>
            <p>
              An unknown error ocurred. Try refreshing the page.
              <br />
              <br />
            </p>
            <p>
              If this problem continues, feel free yell at Jerry Dixon via text,
              call, or facebook rant.
            </p>
          </div>
        );
    }
  };

  // Render the component DOM
  return (
    <Section>
      <SectionHeader>
        <h1>Courses</h1>
        <Button as={Link} to="/courses/create" width="42px" curved>
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </SectionHeader>
      {renderCourses()}
    </Section>
  );
}
